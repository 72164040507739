var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-toolbar'),_c('v-container',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"md4":"","xs12":""}},[_c('v-card',{staticClass:"ma-1 ml-3",class:{ 'mb-n10': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":"","elevation":"4"}},[_c('v-container',[_c('div',{staticClass:"text-overline mb-3"},[_vm._v(" "+_vm._s(_vm.$t('stocks.stockStatus.optional'))+" ")]),_c('FormFields',{attrs:{"single-per-row":"","form":_vm.form,"render":_vm.formRender,"lang-path":"stocks.stockChoose.form."}})],1)],1)],1)],1),_c('x-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"actions":_vm.actions,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"reload":_vm.reload,"api-filter":_vm.apiFilter,"show-search-bar":false},on:{"update:loading":function($event){_vm.loading=$event},"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"item.last_change",fn:function(ref){
var item = ref.item;
return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.last_change,"abs-date-first":true}})]}},{key:"filter-stock_location.id",fn:function(ref){
var filters = ref.filters;
var header = ref.header;
return [_c('v-autocomplete',{attrs:{"items":_vm.has(_vm.stockLocations, _vm.chosenStock) ? _vm.stockLocations[_vm.chosenStock] : [],"label":_vm.$t('base.filterBy') + ' ' + header.text,"loading":_vm.chosenStock !== null && !_vm.has(_vm.stockLocations, _vm.chosenStock),"multiple":"","no-data-text":header.noDataText,"clearable":filters[header.value] !== null},on:{"input":_vm.onLocationChosen},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":header.filterItems,"label":_vm.$t('stocks.stockStatus.table.stock.choose'),"loading":header.loading,"no-data-text":header.noDataText,"prepend-icon":"$stock","clearable":""},on:{"input":function($event){return _vm.onStockChosen($event, header.filterItems)},"click:clear":function($event){filters[header.value] = null; _vm.form.stockId = null}},model:{value:(_vm.chosenStock),callback:function ($$v) {_vm.chosenStock=$$v},expression:"chosenStock"}})],1)],1),(_vm.chosenStock)?_c('v-row',[_c('div',{staticClass:"ml-4 mt-n4 mr-2"},[_c('v-icon',[_vm._v(" $info ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('stocks.stockStatus.useHeaderToSearch'))+" ")])],1)]):_vm._e()],1)]},proxy:true}],null,true),model:{value:(filters[header.value]),callback:function ($$v) {_vm.$set(filters, header.value, $$v)},expression:"filters[header.value]"}})]}},{key:"filter-product_instance.id",fn:function(ref){
var filters = ref.filters;
var header = ref.header;
return [_c('v-autocomplete',{attrs:{"items":_vm.has(_vm.productInstances, _vm.chosenProduct) ? _vm.productInstances[_vm.chosenProduct] : [],"label":_vm.$t('base.filterBy') + ' ' + header.text,"loading":_vm.chosenProduct !== null && !_vm.has(_vm.productInstances, _vm.chosenProduct),"multiple":"","no-data-text":header.noDataText,"clearable":filters[header.value] !== null},on:{"input":_vm.onInstanceChosen},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":header.filterItems,"label":_vm.$t('stocks.stockStatus.table.product_instance.choose'),"loading":header.loading,"no-data-text":header.noDataText,"prepend-icon":"$product","clearable":""},on:{"input":function($event){return _vm.onProductChosen($event, header.filterItems)},"click:clear":function($event){filters[header.value] = null; _vm.onProductChosen(null, header.filterItems)}},model:{value:(_vm.chosenProduct),callback:function ($$v) {_vm.chosenProduct=$$v},expression:"chosenProduct"}})],1)],1),(_vm.chosenProduct)?_c('v-row',[_c('div',{staticClass:"ml-4 mt-n4 mr-2"},[_c('v-icon',[_vm._v(" $info ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('stocks.stockStatus.useHeaderToSearch'))+" ")])],1)]):_vm._e()],1)]},proxy:true}],null,true),model:{value:(filters[header.value]),callback:function ($$v) {_vm.$set(filters, header.value, $$v)},expression:"filters[header.value]"}})]}}])})],1),_c('v-divider',{staticClass:"my-3"}),_c('v-container',[_c('StockBuyPrices')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }