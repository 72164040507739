<template>
  <div>
    <x-toolbar
      :title="pageTitle"
      :tabs="hasTabs"
      :full-width="true"
      :extension="hasTabs"
    >
      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary lighten-1"
          grow
        >
          <v-tabs-slider
            color="accent"
          />
          <v-tab
            v-for="item of tabItems"
            :key="item.label"
          >
            {{ $t('tasks.preparePackage.navigation.' + item.label) }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <template v-if="details">
      <template v-if="assignable">
        <TaskAssignButton
          :loading="loading"
          @assign="assign"
        />
      </template>
      <template v-if="hasTabs">
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item of tabItems"
            :key="item.label"
          >
            <component
              :is="item.component"
              :api="API"
              :task-info="taskInfo"
              :worker-role="item.workerRole"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <PreparePackageAssignment
          :task-info="taskInfo"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import {TaskPreparePackageAPI as API} from "@/api/TaskPreparePackageAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {BarcodeListenerMixin} from "@/app/mixins/BarcodeListenerMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskAssignButton from "@/app/tasks/components/TaskAssignButton.component";
    import PreparePackageAssignment from "@/app/tasks/preparePackage/components/PreparePackageAssignment.component";
    import PreparePackagePrepare from "@/app/tasks/preparePackage/components/PreparePackagePrepare.component";
    import TaskDefaultOverview from "@/app/tasks/components/TaskDefaultOverview.component";
    import {Roles} from "@/enum/roles";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {assign} from "@/utils/object";
    import {tabTitle} from "@/utils/string";

    export default {
        name: "PreparePackageShow",
        components: {PreparePackageAssignment, TaskAssignButton},
        mixins: [ACLMixin, TaskStateMixin, TaskAssignMixin, EventsListenerMixin, BarcodeListenerMixin, RouteParamsMapperMixin],
        data: () => ({
            API: API,
            loading: false,
            type: taskTypes.PREPARE_PACKAGE,
            details: null,
            tab: null
        }),
        computed: {
            events: function () {
                return {
                    'onBarcodeRead': this.activateTab,
                };
            },
            typeName: function () {
                return this.$t(taskNames[this.type]);
            },
            pageTitle: function () {
                let title = '#' + this.taskId + ' ' + this.typeName;
                if (!isNaN(Number.parseInt(this.$route.query.orderId, 10))) {
                    title += ' - ' + this.$t('tasks.preparePackage.order') + ' #' + this.$route.query.orderId;
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    tab: this.tab,
                    taskId: this.taskId,
                };
            },
            tabItems: function () {
                return [{
                    label: 'assignment',
                    component: PreparePackageAssignment
                }]
                    .concat(
                        (
                            this.isPacker || this.isChief
                        ) ? [{
                            label: 'preparePackage',
                            component: PreparePackagePrepare
                        }] : [])
                    .concat(
                        (
                            this.isOpenState && (
                                // TODO chief only when created or waiting for approval?
                                (this.isPacker && this.assignedToCurrentUser && this.isInProgress) || this.isChief
                            )
                        ) ? [{
                            label: 'finish',
                            component: TaskDefaultOverview,
                            workerRole: Roles.PACKER
                        }] : []);
            },
            hasTabs: function () {
                return this.tabItems.length > 1;
            },
        },
        createdOrActivated: function (lifeCycleHook) {
            this.getTask(lifeCycleHook);
        },
        methods: {
            getTask: function (lifeCycleHook) {
                return API.get(this.taskId, true)
                    .then(response => {
                        if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.details = response.data;
                        } else {
                            assign(this.details, response.data);
                        }
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/');
                    });
            }
        }
    };
</script>

<style scoped>

</style>
