import {StockLocationLockType} from "@/enum/stock_location_lock_type";

const stockOrSubStockOrLocationNotFound = "Zadaný sklad, jeho podsklad nebo umístění neexistují!";

export default {
    title: "Sklady",
    link: "Sklady",

    name: "Název skladu",
    description: "Popis",
    is_small: "Malý sklad",
    address: "Adresa",

    chief: "Vedoucí",
    chief_id: "Vedoucí",

    locations: {
        label: "Umístění ve skladu",
        name: "Název umístění",
        code: "Kód",
        availability: "Dostupnost",
        availability_hint: "Vyšší číslo znamená lépe dostupné.",
        setAvailability: "Nastavit dostupnost",
        availabilityBatch: {
            failed: "Nezdařilo se nastavit dostupnost všech umístění!",
            done: "Dostupnost nastavena."
        },
        is_expedition: "Je balicí",
        expedition: "Balicí",
        is_mobile: "Je mobilní",
        mobile: "Mobilní",
        nonMobile: "Nemobilní",
        codehint: "Nechte prázdné pro automaticky generovaný.",
        none: "<neumístěno>",
        at: "na umístění",
        is_allowed: "Povolené pro tento podsklad",
        stockStatus: "Položky na umístění",
        searchHint: "Prohledává název umístění a kód",

        printBarcode: "Vytisknout štítek",
        printBarcodeMany: "Vytisknout štítky",
        printBarcodeHowMuch: "Kolik štítků '{0}' vytisknout?",
        printBarcodesHowMuch: "Kolik štítků vytisknout?",
        printDone: "Tisknu štítek '{0}'...",

        items: {
            productsAtSubstock: "Položky na zvoleném umístění",
            noItems: "Na zvoleném umístění nic není!"
        },

        locks: {
            strict_mode: "Je striktní",
            strictHint: "Striktní zámek umožňuje položit produkty pouze na ta umístění, na které jsou uzamčené.",
            note: "Poznámka",
            lockTypes: {
                title: "Toto umístění je omezeno na {0}",

                [StockLocationLockType.MANUFACTURER]: "Výrobce",
                [StockLocationLockType.PRODUCT_TYPE]: "Typ skladové položky",
                [StockLocationLockType.PRODUCT]: "Skladovou položku",
                [StockLocationLockType.INSTANCE]: "Konkrétní skladovou položku",
            },
            lockItems: {
                title: "Omezit umístění na",

                [StockLocationLockType.MANUFACTURER]: "Výrobce",
                [StockLocationLockType.PRODUCT_TYPE]: "Typ skladové položky",
                [StockLocationLockType.PRODUCT]: "Skladová položka",
                [StockLocationLockType.INSTANCE]: "Konkrétní skladová položka",
            },

            newType: "Typ nového zámku",
            removeType: "Zámek na {0}",

            removeLock: "Odebrat zámek",
            unableToRemoveLastLock: "Nelze odebrat poslední zámek!",
            lockAlreadySelected: "Tento zámek je již zvolen!",
            allTypesExist: "Toto umístění je již omezeno na všechny možné typy!",

            create: {
                done: "Zámek na {0} vytvořen.",
                titleSimple: "Přidat zámek"
            },
            update: {
                done: "Zámek na {0} upraven."
            },
            delete: {
                done: "Zámek na {0} smazán."
            },

            status: {
                404: "Zadaný sklad, umístění nebo typ zámku neexistuje!",
                409: "Nesprávný typ zámku!"
            }
        },

        show: {
            titleSimple: "Umístění",
            title: "Umístění '{0}' v '{1}'",
            label: "Položky na umístění"
        },
        create: {
            titleSimple: "Nové umístění",
            title: "Nové umístění v {0}",
            label: "Vytvořit nové umístění",
            chooseStockAndSubstockFirst: "Nejdříve vyberte sklad a podsklad!",
            done: "Umístění '{0}' vytvořeno."
        },
        update: {
            titleSimple: "Upravit umístění",
            title: "Upravit umístění '{0}' v {1}",
            done: "Umístění '{0}' upraveno."
        },
        delete: {
            done: "Umístění '{0}' smazáno.",
            failed: "Umístění'{0}' nelze smazat. Zkuste to prosím znovu později.",
            status: {
                404: "Zadaný sklad nebo umístění neexistuje!",
                409: "Umístění nemůže být smazáno kvůli jeho vztahům k dalším objektům!"
            }
        },

        status: {
            400: "Nesprávný formát data!",
            404: "Zadaný sklad nebo umístění neexistuje!",
            409: "Zadaný kód již využivá jiné umístění!"
        }
    },

    substocks: {
        label: "Podsklady",
        name: "Název podskladu",
        description: "Popis",
        noSubstocks: "Žádné podsklady",
        owner: {
            name: "Vlastník"
        },
        owner_id: "Vlastník",
        prepare_package_mode: "Režim balení zásilek",
        order_with_reservation: "Rezervovat objednané zboží",
        auto_merge_stock_pickings: "Automaticky slučovat podobná vyskladnění",
        auto_merge_stock_pickings_hint: "Nemá vliv na vyskladnění vytvořená z externích objednávek.",

        items: {
            productsAtSubstock: "Položky ve zvoleném podskladu",
            noItems: "Ve zvoleném podskladu nic není!"
        },

        show: {
            titleSimple: "Podsklad",
            title: "Podsklad '{0}' v '{1}'"
        },
        create: {
            titleSimple: "Nový podsklad",
            title: "Nový podsklad v {0}",
            label: "Vytvořit nový podsklad",
            done: "Podsklad '{0}' vytvořen.",
            addedPermission: "'{0}' má nyní přístup k '{1}'. Doporučujeme nastavit i pro ostatní uživatele."
        },
        update: {
            titleSimple: "Upravit podsklad",
            title: "Upravit podsklad '{0}' v '{1}'",
            done: "Podsklad '{0}' upravena."
        },
        delete: {
            done: "Podsklad '{0}' smazán.",
            failed: "Podsklad '{0}' nelze smazat. Zkuste to prosím znovu později.",
            status: {
                404: "Zadaný sklad nebo jeho podsklad neexistují!",
                409: "Podsklad nemůže být smazán kvůli jeho vztahům k dalším objektům!"
            }
        },

        status: {
            404: "Zadaný sklad nebo jeho podsklad neexistují!",
            409: "Podsklad nemůže být smazán kvůli jeho vztahům k dalším objektům!"
        },

        allowedLocations: {
            status: {
                404: "Zadaný sklad, jeho podsklad nebo konkrétní skladová položka neexistují!"
            }
        },

        allowedInstances: {
            status: {
                404: stockOrSubStockOrLocationNotFound
            }
        },

        whitelist: {
            list: "Seznam povolených umístění v '{0}'",
            available: "Umístění, která lze povolit pro '{0}'",
            auto: "Automaticky povolené",

            create: {
                title: "Přidat do seznamu povolených umístění",
                done: "Umístění '{0}' povoleno",
                failed: "Nezdařilo se povolit '{0}'"
            },
            delete: {
                title: "Odebrat ze seznamu povolených umístění",
                done: "Umístění '{0}' zakázáno",
                failed: "Nezdařilo se zakázat umístění '{0}'",
                status: {
                    404: stockOrSubStockOrLocationNotFound,
                    409: "Umístění nemůže být odebráno z podskladu, protože je na něm umístěno zboží!"
                }
            },
            batch: {
                true: "Povolit vyfiltrované",
                false: "Odebrat povolení vyfiltrovaných",
                checked: {
                    true: "Povolit pro podsklad",
                    false: "Odebrat povolení pro podsklad",
                },
                done: {
                    true: "Umístění povolena",
                    false: "Zrušeno povolení",
                },
                failed: "Změna povolení se nezdařila!"
            },
            status: {
                404: "Zadaný sklad, podsklad nebo umístění neexistují!",
                409: "Zvolené umístení je již v tomto podskladu povolené!"
            }
        },

        prepare_package_mode_items: {
            EAN_READ_EACH: "Vyžadovat napípnutí každé položky",
            SELECT_EACH: "Umožnit volbu každé položky ručně",
            SELECT_FIRST_AUTO_PACK_REST: "Umožnit volbu první položky ručně, pak automaticky zabalit vše z dané objednávky"
        },

        task_settings: {
            label: "Konfigurace úkolů",
            autoApprove: "Automaticky schvalovat dokončené úkoly",
            autoPrintReport: "Automaticky tisknout reporty při dokončení úkolu",
            updateDone: "Nastavení upraveno",
            status: {
                404: "Zadaný podsklad neexistuje!"
            }
        },

        order_processing: {
            label: "Konfigurace automatického slučování vyskladnění objednávek",
            runNow: "Spustit vytvoření sloučených vyskladnění z objednávek ihned",
            enable_automatic_triggering: "Spouštět automaticky",
            max_automatic_sets: "Maximální počet sloučených vyskladnění současně",
            min_orders_per_automatic_set: "Minimální počet objednávek ve sloučeném vyskladnění",
            max_orders_per_automatic_set: "Maximální počet objednávek ve sloučeném vyskladnění",
            strategies: "Strategie tvorby sloučeného vyskladnění",
            strategyPriority: "Priorita strategií",
            strategy: {
                SAME_SINGLE_PRODUCT: "Stejné jednopoložkové",
                SINGLE_PRODUCT: "Jednopoložkové",
                IDENTICAL_ORDERS: "Stejné",
                FROM_OLDEST: "Od nejstarších"
            },
            updateDone: "Nastavení upraveno",
            run: {
                status: {
                    204: "Úloha byla zařazena do fronty ke zpracování",
                    404: "Zadaný sklad nebo jeho podsklad neexistují!",
                }
            },
            status: {
                404: "Zadaný sklad nebo jeho podsklad neexistují!",
                409: "Neplatná konfigurace zpracování objednávek!"
            }
        },

        buyer_types: {
            label: "Přiřazené typy odběratelů"
        }
    },

    owners: {
        name: "Název",
        ico: "IČO",
        dic: "DIČ",
        phone: "Telefon",
        email: "E-mail",
        website: "Web",
        billing_address: "Fakturační adresa",
        bank_account: "Číslo účtu",

        unableToLoad: "Vlastníka nebylo možné načíst. Zkuste to prosím znovu.",

        link: "Vlastníci podskladů",

        list: {
            title: "Vlastníci podskladů"
        },
        show: {
            title: "Vlastník podskladu"
        },
        create: {
            label: "Vytvořit nového vlastníka",
            titleSimple: "Nový vlastník podskladu",
            title: "Nový vlastník podskladu '{0}'",
            done: "Vlastník '{0}' vytvořen."
        },
        update: {
            titleSimple: "Upravit vlastníka",
            title: "Upravit vlastníka '{0}'",
            done: "Vlastník '{0}' upraven."
        },
        delete: {
            done: "Vlastník '{0}' smazán!",
            failed: "Vlastníka '{0}' nelze smazat. Zkuste to prosím znovu později.",
            status: {
                404: "Zadaný vlastník podskladu neexistuje!",
                409: "Vlastník podskladu nemůže být smazán kvůli jeho vztahům k dalším objektům!"
            }
        },

        status: {
            404: "Zadaný vlastník skladu neexistuje!",
            409: "Vyplněné IČO již má jiný vlastník skladu!"
        }
    },

    stockChoose: {
        form: {
            stockId: "Zvolte sklad",
            subStockId: "Zvolte podsklad",
            locationId: "Pouze na umístění ...",
            productId: "Pouze skladová položka ...",
            instanceId: "Pouze konkrétní skladová položka ...",
        }
    },

    stockStatus: {
        link: "Stav skladu",
        title: "Přehled stavu skladu",
        allSubstocks: "<všechny podsklady>",
        allLocations: "<všechna umístění ve skladě>",
        allLocationsIn: "<všechna umístění v '{0}'>",
        allProducts: "<všechny položky '{0}'>",
        optional: "Volitelné upřesnení",
        table: {
            stock: {
                id: "Sklad",
                choose: "Zvolte sklad"
            },
            product_instance: {
                id: "Název položky",
                choose: "Zvolte produkt"
            },
            stock_location: {
                id: "Umístění nebo skladník"
            },
            quantity: "Množství",
            last_change: "Datum"
        },
        showItem: "Zobrazit položku",
        showLocation: "Zobrazit umístění",
        useHeaderToSearch: "Pro vyhledávání použijte pole v hlavičce tabulky",

        locations: {
            link: "Report umístění",
            title: "Report využití umístění",
            generating: "Report se připravuje...",
            form: {
                stockId: "Sklad",
                subStockId: "Podsklad",
                dateFrom: "od",
                dateTo: "do",
                mustBeHigherThanFrom: "Nesmí být dříve než 'od'!",
                mustBeUpToToday: "Nesmí být později než dnes!",
                max31Days: "Maximálně 31 dnů!"
            },
            table: {
                location_id: "ID umístění",
                location_name: "Název umístění",
                days_used: "Využito dnů"
            }
        },

        buyPrices: {
            title: "Export nákupních cen",

            stockId: "Sklad",
            subStockId: "Podsklad",
            dateTo: "do",

            status: {
                409: "Neplatné datum"
            }
        }
    },

    stockMovements: {
        link: "Skladové pohyby",
        title: "Přehled pohybů na skladě",
        allProducts: "<všechny skladové položky>",
        dateFrom: "Od ...",
        dateTo: "Do ...",
        selectDate: "Zvolte datum",
        selectTime: "Zvolte čas",
        table: {
            amount: "Množství",
            created_at: "Datum",
            created_by: "Uživatel",
            product: "Skladová položka",
            movement: "Pohyb",
            task: "Úkol"
        },
        viewTask: "Zobrazit úkol",
        filterProduct: "Filtrovat pouze tuto položku",
        filterTask: "Filtrovat pouze tento úkol",
        removeFilter: "Odstranit filtr"
    },

    list: {
        unableToLoad: "Seznam skladů nebylo možné načíst. Zkuste to prosím znovu později.",
    },
    show: {
        titleSimple: "Detail skladu",
        title: "{0}"
    },
    create: {
        title: "Nový sklad",
        done: "Sklad '{0}' vytvořen."
    },
    update: {
        titleSimple: "Upravit sklad",
        title: "Upravit {0}",
        done: "Sklad '{0}' upraven."
    },
    delete: {
        done: "Sklad '{0}' smazán.",
        failed: "Sklad '{0}' nelze smazat. Zkuste to prosím znovu později."
    },

    status: {
        400: "Nesprávný formát data!",
        404: "Zadaný sklad neexistuje!",
        409: "Sklad nemůže být smazán kvůli jeho vztahům k dalším objektům!",
        423: "Report ještě není připravený. Zkuste prosím akci opakovat později."
    }
};
