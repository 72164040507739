<template>
  <div>
    <template v-if="details">
      <v-layout wrap>
        <v-flex
          md4
          xs12
        >
          <v-card
            class="ma-1"
          >
            <v-container>
              <div>
                <span class="text-caption">{{ $t('tasks.state.name') }}:</span>
                {{ details.state | taskState }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.priority.name') }}:</span>
                {{ details.priority | taskPriority }}
              </div>
              <div v-if="details.external_id">
                <span class="text-caption">{{ $t('tasks.external_id') }}:</span>
                {{ details.external_id }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.assigned_user') }}:</span>
                {{ details.assigned_user | fullName }}
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.time_spent') }}:</span>
                {{ details.time_entries | timeEntriesSumHumanize }}
              </div>
              <div v-if="details.description">
                <span class="text-caption">{{ $t('tasks.description') }}:</span>
                {{ details.description }}
              </div>
              <v-divider
                class="my-2"
              />
              <div v-if="details.external_order_states">
                <span class="text-caption">{{ $t('tasks.externalOrder.state.label') }}:</span>
                <ul>
                  <li
                    v-for="state of details.external_order_states"
                    :key="state"
                  >
                    {{ $t('tasks.externalOrder.state.' + state) }}
                  </li>
                </ul>
              </div>
              <div>
                <span class="text-caption">{{ $t('tasks.externalOrder.subordinate_stock_id') }}:</span>
                {{ details.subordinate_stock.name }}
              </div>
              <div v-if="details.buyer">
                <span class="text-caption">{{ $t('tasks.externalOrder.buyer_id') }}:</span>
                <router-link :to="'/buyers/' + details.buyer.id">
                  {{ details.buyer.name }}
                </router-link>
              </div>
              <div v-if="details.carrier">
                <span class="text-caption">{{ $t('tasks.externalOrder.carrier') }}:</span>
                <router-link :to="'/carriers/' + details.carrier.id">
                  {{ details.carrier.name }}
                </router-link>
              </div>
              <ExternalOrderParametersList :order="details" />
              <div>
                <span class="text-caption">{{ $t('tasks.externalOrder.externalFields.label') }}:</span>
                <ul>
                  <div
                    v-for="field of ['order_id', 'note', 'author_name', 'author_id', 'id']"
                    :key="field"
                  >
                    <li v-if="details['external_' + field]">
                      <span class="text-caption">{{
                        $t('tasks.externalOrder.externalFields.' + field)
                      }}</span>:
                      {{ details['external_' + field] }}
                    </li>
                  </div>
                  <li v-if="details.external_order_created_at">
                    <span class="text-caption">{{
                      $t('tasks.externalOrder.externalFields.order_created_at')
                    }}</span>:
                    <DateTimeWithTooltip :date-time="details.external_order_created_at" />
                  </li>
                </ul>
              </div>
              <div>
                <span class="text-caption">{{
                  $t('tasks.preparePackage.shipments.lastValidated')
                }}:</span>
                <template
                  v-if="details.last_validated !== null || details.last_validation_try !== null"
                >
                  <DateTimeWithTooltip
                    :date-time="details.last_validated || details.last_validation_try"
                  />
                </template>
                <template v-else>
                  {{ $t('tasks.preparePackage.shipments.never') }}
                </template>
              </div>
              <div v-if="details.stock_picking_task_ids.length > 0">
                <span class="text-caption">{{
                  $t('tasks.externalOrder.stock_picking_task_ids')
                }}:</span>
                <span
                  v-for="(stockPickingId, index) of details.stock_picking_task_ids"
                  :key="stockPickingId"
                >
                  <span v-if="index > 0">, </span>
                  <router-link :to="'/stock-picking/' + stockPickingId">
                    #{{ stockPickingId }}
                  </router-link>
                </span>
              </div>
              <div v-if="details.prepare_shipment_package_task_ids.length > 0">
                <span class="text-caption">{{
                  $t('tasks.externalOrder.prepare_shipment_package_task_ids')
                }}:</span>
                <span
                  v-for="(prepareShipmentId, index) of details.prepare_shipment_package_task_ids"
                  :key="prepareShipmentId"
                >
                  <span v-if="index > 0">, </span>
                  <router-link :to="'/prepare-package/' + prepareShipmentId">
                    #{{ prepareShipmentId }}
                  </router-link>
                </span>
              </div>
              <div v-if="details.shipment_ids.length > 0">
                <span class="text-caption">{{ $t('tasks.externalOrder.shipment_ids') }}:</span>
                <span
                  v-for="(shipmentId, index) of details.shipment_ids"
                  :key="shipmentId"
                >
                  <span v-if="index > 0">, </span>
                  <router-link :to="'/shipping?shipmentId=' + shipmentId">
                    #{{ shipmentId }}
                  </router-link>
                </span>
              </div>
              <v-divider
                class="my-2"
              />
              <EntityHistory :changes="details" />
            </v-container>
          </v-card>
        </v-flex>
        <v-flex
          v-if="details.notes.length > 0"
          md4
          xs12
        >
          <Notes :notes="details.notes" />
        </v-flex>
        <v-flex
          md4
          xs12
        >
          <TaskAttachments
            :attachments="details.attachments"
          />
        </v-flex>
        <v-flex
          v-if="isChief && isCreated"
          md4
          xs12
        >
          <v-card>
            <v-card-title>
              {{ $t('tasks.externalOrder.processingMode.label') }}
            </v-card-title>
            <v-card-text>
              <v-form class="d-flex flex-row align-top">
                <v-autocomplete
                  v-model="selectedProcessingMode"
                  dense
                  outlined
                  :loading="loading"
                  :readonly="loading"
                  :items="possibleProcessingModes"
                  prepend-icon="$taskExternalOrderProcessingMode"
                  persistent-hint
                  :hint="$t('tasks.externalOrder.processingMode.hint.' + selectedProcessingMode)"
                  @keyup.enter.native="confirmProcessingMode"
                >
                  <template #item="{ item }">
                    <v-layout>
                      <v-flex>
                        {{ item.text }}
                      </v-flex>
                      <v-flex class="text-right">
                        <v-tooltip right>
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-icon>$info</v-icon>
                            </div>
                          </template>
                          <span>{{
                            $t('tasks.externalOrder.processingMode.hint.' + item.value)
                          }}</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-autocomplete>
                <v-btn
                  :outlined="loadedProcessingMode === selectedProcessingMode"
                  :disabled="selectedProcessingMode === null || loadedProcessingMode === selectedProcessingMode"
                  :loading="loading"
                  class="ml-2"
                  type="submit"
                  color="accent"
                  @click.prevent="confirmProcessingMode"
                >
                  {{ $t('base.save') }}
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>

<script>
    import DateTimeWithTooltip from "@/app/components/DateTimeWithTooltip";
    import EntityHistory from "@/app/components/EntityHistory.component";
    import TaskAttachments from "@/app/tasks/components/TaskAttachments.component";
    import Notes from "@/app/tasks/components/TaskNotes.component";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import {TaskExternalOrderProcessingMode} from "@/enum/task_external_order_processing_mode";
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import ExternalOrderParametersList
        from "@/app/tasks/externalOrder/components/ExternalOrderParametersList.component";

    export default {
        name: "ExternalOrderAssignment",
        components: {ExternalOrderParametersList, DateTimeWithTooltip, EntityHistory, TaskAttachments, Notes},
        mixins: [ACLMixin, TaskStateMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            loading: false,
            loadedProcessingMode: null,
            selectedProcessingMode: null
        }),
        computed: {
            details: function () {
                return this.taskInfo.details;
            },
            possibleProcessingModes: function () {
                return Object.values(TaskExternalOrderProcessingMode).map(val => ({
                    text: this.$t('tasks.externalOrder.processingMode.' + val),
                    value: val
                }));
            }
        },
        watch: {
            'taskInfo.details.processing_mode': {
                handler: function (newValue) {
                    this.loadedProcessingMode = newValue;
                    this.selectedProcessingMode = newValue;
                },
                immediate: true
            }
        },
        methods: {
            confirmProcessingMode: function () {
                this.loading = true;
                TaskExternalOrderAPI.updateProcessingMode(this.taskInfo.taskId, this.selectedProcessingMode)
                    .then(() => this.$set(this.taskInfo.details, 'processing_mode', this.selectedProcessingMode))
                    .catch(this.snack)
                    .finally(() => this.loading = false);
            }
        }
    };
</script>

<style scoped>

</style>
