<template>
  <v-list-item
    class="priority"
    :class="'p' + priority"
    ripple
    :to="to"
  >
    <v-list-item-avatar>
      <v-icon
        :color="priority !== 2 ? TaskPriorityColor[priority] : 'default'"
        x-large
      >
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ header }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="content">
        {{ content }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="subContent">
        {{ subContent }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              {{ actionText }}
            </span>
          </template>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="actionTextTooltip" />
        </v-tooltip>
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
    import {TaskPriority, TaskPriorityColor} from "@/enum/task_priority";

    export default {
        name: "TaskListTask",
        props: {
            icon: {
                type: String,
                default: '$task'
            },
            header: {
                type: String,
                default: ''
            },
            content: {
                type: String,
                default: ''
            },
            subContent: {
                type: String,
                default: null
            },
            actionText: {
                type: String,
                default: ''
            },
            actionTextTooltip: {
                type: String,
                default: ''
            },
            priority: {
                type: Number,
                default: TaskPriority.NORMAL
            },
            to: {
                type: String,
                default: '/'
            },
        },
        data: () => ({
            TaskPriorityColor: TaskPriorityColor
        })
    };
</script>

<style scoped lang="sass">
$border-style: solid 3px

.priority
    border-radius: 3px

.p1
    border-left: $border-style var(--v-priorityLow-base)

.p2
    border-left: $border-style var(--v-priorityNormal-base)

.p3
    border-left: $border-style var(--v-priorityHigh-base)

.v-list-item__action
    max-width: 15%
</style>
