import {BaseAPI} from "@/api/BaseAPI";
import {APIHelper} from "@/service/APIHelper";
import {merge} from "@/utils/object";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";

/**
 * This includes also "storekeeper inventories"
 */
const StockStatusAPI = {

    API: BaseAPI,
    DOMAIN: 'stock-status',
    DOMAIN_RESERVATION: 'reservations',
    DOMAIN_RESERVATIONS_WITH_ORDERS: 'reservations-with-orders',
    DOMAIN_LOCATIONS: 'locations',
    DOMAIN_PRODUCT_INSTANCES: 'product-instances',
    DOMAIN_STOCKS: 'stocks',
    DOMAIN_BY_DATE: 'by-date',
    LANG: 'stocks',
    LANG_LOCATIONS: 'locations',

    /**
     * @param params {{
     *     page: int
     *     itemsPerPage: int
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getAll(...params) {
        return this.API.get(this.DOMAIN, undefined, merge(...params));
    },
    getAllPages(params) {
        return APIHelper.getAllPages(this.DOMAIN, undefined, params);
    },

    getShortAllPages(params) {
        return APIHelper.getAllPages('stock-status-short', this.DOMAIN, params);
    },

    /**
     * @param subStockId {int}
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getCurrentInStock(subStockId) {
        return this.getAllPages({
            filter: APIFilters.makeFilter({
                [APIFilterOP.AND]: [
                    {[APIFilterOP.EQUALS]: {'substock.id': subStockId}},
                    {[APIFilterOP.GREATER_THAN]: {'quantity': 0}}
                ]
            })
        });
    },

    /**
     * @param subStockId {int}
     * @return {Promise<AxiosResponse<*>|never>}
     */
    getCurrentReservations(subStockId) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_RESERVATION],
            this.LANG
        );
    },

    /**
     * @param stockId {int}
     * @param locationId {int}
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getCurrentOnLocationInWholeStock(stockId, locationId) {
        return this.API.get(
            [this.DOMAIN, this.DOMAIN_STOCKS, stockId, this.DOMAIN_LOCATIONS, locationId],
            [this.LANG, this.LANG_LOCATIONS]
        );
    },

    /**
     * @param subStockId {int}
     * @param locationId {int}
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getCurrentOnLocation(subStockId, locationId) {
        return this.getAllPages({
            filter: APIFilters.makeFilter([
                {
                    [APIFilterOP.EQUALS]: {
                        'substock.id': subStockId
                    }
                }, {
                    [APIFilterOP.EQUALS]: {
                        'stock_location.id': locationId
                    }
                }
            ])
        });
    },

    /**
     * @param subStockId {int}
     * @param instanceId {int}
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getCurrentForInstance(subStockId, instanceId) {
        return this.getAllPages({
            filter: APIFilters.makeFilter([
                {
                    [APIFilterOP.EQUALS]: {
                        'substock.id': subStockId
                    }
                }, {
                    [APIFilterOP.EQUALS]: {
                        'product_instance.id': instanceId
                    }
                },
                {
                    [APIFilterOP.GREATER_THAN]: {
                        quantity: 0
                    }
                }
            ])
        });
    },

    /**
     * @param subStockId {int}
     * @param instanceId {int}
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getCurrentReservationsForInstance(subStockId, instanceId) {
        return this.API.get(
            [this.DOMAIN, subStockId, this.DOMAIN_PRODUCT_INSTANCES, instanceId, this.DOMAIN_RESERVATION],
            this.LANG
        );
    },

    /**
     * @param params {{
     *     filter: {Object}
     *     sort: {
     *         key: 'ASC'|'DESC'
     *     }
     * }}
     * @returns {Promise<unknown>}
     */
    getCurrentReservationsWithOrders(params) {
        return APIHelper.getAllPages([this.DOMAIN, this.DOMAIN_RESERVATIONS_WITH_ORDERS], undefined, params);
    },

    /**
     * @polling
     * @param subStockId {int}
     * @param fromDate {string} ISO-8601 datetime
     * @param toDate {string} ISO-8601 datetime. Cannot be more than 31 days after fromDate
     * @returns {Promise<AxiosResponse<any>|never>}
     */
    getLocationUsage(subStockId, fromDate, toDate) {
        return APIHelper.polling(this.API.get.bind(
            this.API,
            [this.DOMAIN, subStockId, this.DOMAIN_LOCATIONS, 'from-date', fromDate, 'to-date', toDate],
            this.LANG
        ), 33); // ~4.5 minutes
    },

    exportBuyPrices(subStockId, toDate) {
        return this.API.post(
            ['exports', 'buy-prices'],
            {
                substock_id: subStockId,
                to_date: toDate
            },
            [this.LANG, 'stockStatus', 'buyPrices']
        );
    }
};

export {StockStatusAPI};
