<template>
  <v-card
    outlined
    elevation="4"
  >
    <v-container>
      <div class="text-overline mb-3">
        {{ $t('stocks.stockStatus.buyPrices.title') }}
      </div>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <FormFields
          :form="form"
          :render="formRender"
          lang-path="stocks.stockStatus.buyPrices."
        />
        <v-btn
          :text="!valid"
          :loading="loading"
          type="submit"
          color="accent"
          @click="submit"
        >
          <v-icon
            class="mr-2"
          >
            $export
          </v-icon>
          {{ $t('form.download') }}
        </v-btn>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {StockBuyPricesForm, StockBuyPricesRender} from "@/app/overview/status/definitions/stockBuyPrices.form";
    import {StockStatusAPI} from "@/api/StockStatusAPI";
    import {FormAutocompleteMixin} from "@/app/mixins/FormAutocompleteMixin";
    import * as Export from "@/service/Export";

    export default {
        name: "StockBuyPrices",
        components: {FormFields},
        mixins: [FormAutocompleteMixin],
        data: () => ({
            form: new StockBuyPricesForm(),
            formRender: new StockBuyPricesRender(),
            valid: false,
            loading: false
        }),
        watch: {
            'form.stockId': function () {
                this.form.subStockId = null;
                if (this.form.stockId) {
                    this.fetchSubStocks();
                }
            }
        },
        methods: {
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                if (!this.form.dateTo) {
                    return;
                }
                this.loading = true;
                StockStatusAPI.exportBuyPrices(this.form.subStockId, this.form.dateTo)
                    .then(response => {
                        Export.url('data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(response.data), 'buy_prices_export_' + this.form.dateTo);
                    }).catch(this.snack)
                    .finally(() => this.loading = false);
            }
        }
    };
</script>

<style scoped>

</style>
