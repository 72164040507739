import {StocksRender} from "@/app/overview/definitions/overview.render.form";

function StockBuyPricesForm() {
    return {
        stockId: null,
        subStockId: null,
        dateTo: null
    };
}

function StockBuyPricesRender() {
    return {
        ...(new StocksRender(true, true)),
        dateTo: {
            dateTimePicker: true,
            required: true,
            sticky: true
        }
    };
}

export {StockBuyPricesForm, StockBuyPricesRender};
